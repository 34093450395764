import { navigate } from 'gatsby';
import { useLayoutEffect } from 'react';

import { useLeadOrigin } from '@src/hooks/useLeadOrigin';

const LandingPage = () => {
  const { origin = 'bodil' } = useLeadOrigin();

  useLayoutEffect(() => {
    navigate(`/${origin}/address`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default LandingPage;
